import React, { Component } from 'react'
import AsyncSelect from 'react-select/async';
import Select, { components } from 'react-select';

const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
};

export default function ReactSelect({ key = null, placeholder = null, readOnly = false, id = null, name = null, onChange = null, loadOptions = null, options = null, value = null }) {
    if (options) {//opções fixas
        return <Select
            placeholder={placeholder}
            components={{ Placeholder }}
            styles={{
                control: (base, state) => ({
                    ...base,
                    background: "#f7faff",
                }),
            }}
            key={key}
            id={id}
            isDisabled={readOnly}
            name={name}
            value={options.filter(option => option.value === value.value)}
            onChange={onChange}
            options={options}
            isOptionDisabled={(option) => option.disabled === true}
        />

    } else {//opções via api
        return <AsyncSelect
            placeholder={placeholder}
            components={{ Placeholder }}
            styles={{
                control: (base, state) => ({
                    ...base,
                    background: "#f7faff",
                }),
            }}
            key={key}
            id={id}
            isDisabled={readOnly}
            name={name}
            defaultOptions
            value={value}
            onChange={onChange}
            loadOptions={loadOptions}
            isOptionDisabled={(option) => option.disabled === true}
        />
    }
}
