import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../services/api';
import { useAppContext } from '../AppContext';
import { BsDoorOpenFill } from 'react-icons/bs';

export default function NavBarCliente({ titulo = null, menu = [] }) {
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const { sharedState, setSharedState } = useAppContext();
    const [empresas, setEmpresas] = useState(JSON.parse(localStorage.getItem("empresas") ?? "[]"));
    const [empresaAtual, setEmpresaAtual] = useState({ id: localStorage.getItem("empresa"), nome: localStorage.getItem("nomeEmpresa") });
    const history = useHistory();
    const logout = (e) => {
        e.preventDefault();
        localStorage.removeItem("token");
        localStorage.removeItem("empresa");
        localStorage.removeItem("empresas");
        history.push('/login');
    }
    const buscar = () => {
        api({
            method: 'get',
            action: '/participante',
            token: token,
            params: {
                ...{
                    orderBy: {
                        nome: "desc"
                    }
                },
                ...{ tipoCadastro: "empresa" }
            }
        })
            .then(response => {
                if (localStorage.getItem("empresa") === null) {
                    localStorage.setItem("empresa", response.data.data[0].id);
                    localStorage.setItem("nomeEmpresa", response.data.data[0].nomefantasia || response.data.data[0].nome);
                    empresaAtual.id = response.data.data[0].id;
                    empresaAtual.nome = response.data.data[0].nomefantasia || response.data.data[0].nome;
                    localStorage.setItem("empresas", JSON.stringify(response.data.data));
                }
                setEmpresas(response.data.data);
            }).catch(err => {
                //console.log(err)
            });
    };

    useEffect(() => { //onload
        var empresasArray = JSON.parse(localStorage.getItem("empresas") ?? "{}");
        if ((localStorage.getItem("empresa") === null)
            || (empresasArray.length === 0)) {
            buscar();
        }
    },
        // eslint-disable-next-line
        []);

    return (
        <nav
            className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
            id="navbarBlur"
            data-scroll="false"
        >
            <nav aria-label="breadcrumb" style={{ minWidth: "65%" }}>
                <h5 className="font-weight-bolder text-white mb-0">{titulo}</h5>
            </nav>
            <div className="container-fluid py-1 px-3">
                <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                    <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                    </div>
                    <ul className="navbar-nav justify-content-end">
                        <li className="nav-item d-xl-none ps-3 d-flex align-items-center"
                            style={{ cursor: "pointer", marginRight: "10px" }}
                            onClick={() => {
                                if (sharedState.sidebar) {
                                    sharedState.sidebar = false;
                                } else {
                                    sharedState.sidebar = true;
                                }
                                setSharedState({ ...sharedState });
                            }}>
                            <div className="nav-link text-white p-0" id="iconNavbarSidenav">
                                <div className="sidenav-toggler-inner">
                                    <i className="sidenav-toggler-line bg-white"></i>
                                    <i className="sidenav-toggler-line bg-white"></i>
                                    <i className="sidenav-toggler-line bg-white"></i>
                                </div>
                            </div>
                        </li>
                        {menu.map((item) => {
                            return <li className="nav-item d-flex align-items-center">
                                {item}
                                <div className='text-white font-weight-bold px-0' style={{ marginLeft: "10px", marginRight: "10px" }}> | </div>
                            </li>;
                        })}
                        <li className="nav-item d-flex align-items-center">
                            <a
                                href="/sair"
                                onClick={(e) => logout(e)}
                                className="nav-link text-white font-weight-bold px-0"
                            >
                                <span className="d-sm-inline"><BsDoorOpenFill />Sair</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}