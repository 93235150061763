import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiLogIn } from 'react-icons/fi';
import { FaUser, FaEnvelope, FaLock } from 'react-icons/fa';
import { dataMysql, extract } from '../../util/util';
import api from '../../services/api';
import logo from '../../images/logo.svg';
import './styles.css';
import { toast } from 'react-toastify';

export default function Login() {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const history = useHistory();

  async function handleLogin(e) {
    e.preventDefault();

    try {
      const response = await api({
        method: 'post',
        action: '/login',
        data: { email, senha }
      });
      var dadosRecebidos = extract({
        jsonLaravel: response.data,
        chaves: ['tiposla']
      });

      //if (dadosRecebidos.empresa) {
      //  localStorage.setItem('empresa', dadosRecebidos.tiposla);
      //  localStorage.setItem('nomeTiposla', dadosRecebidos.nomeTiposla);
      //}

      if (dadosRecebidos.cargo && dadosRecebidos.cargo.perfil) {
        localStorage.setItem('perfil', dadosRecebidos.cargo.perfil.codigo);
      } else {
        localStorage.removeItem('perfil');
      }

      if (dadosRecebidos.tiposla) {
        localStorage.setItem('tiposla', dadosRecebidos.tiposla);
        localStorage.setItem('nomeTiposla', dadosRecebidos.nomeTiposla);
      } else {
        localStorage.removeItem('tiposla');
        localStorage.removeItem('nomeTiposla');
      }

      //logomarca do cliente
      if (dadosRecebidos.cliente?.logomarca?.link) {//existe logomarca no evento
        localStorage.setItem('logomarcaEvento', dadosRecebidos.cliente.logomarca.link);
      }

      localStorage.setItem('idUsuario', dadosRecebidos.id);
      localStorage.setItem('nomeUsuario', dadosRecebidos.nome);
      localStorage.setItem('token', dadosRecebidos.api_token);
      history.push('/evento');
    } catch (err) {
      toast.error('Falha no login, tente novamente');
    }
  }

  return (
    <>
      <main className="main-content  mt-0">
        <div className="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg" style={{ backgroundImage: "url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg')", backgroundPosition: "top" }}>
          <span className="mask bg-gradient-dark opacity-6"></span>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 text-center mx-auto">
                <h1 className="text-white mb-2 mt-5">Bem Vindo(a)!</h1>
                <p className="text-lead text-white">RSVP Online Access-Mkt</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
            <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
              <div className="card z-index-0">
                <div className="row px-xl-5 px-sm-4 px-3">
                  <div className="mt-2 position-relative text-center py-4">
                    <img alt="" src={logo} height="48" />
                  </div>
                  <div className="mt-2 position-relative text-center">
                    <p className="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3">
                      Informe seu nome de usuário e senha
                    </p>
                  </div>
                </div>
                <div className="card-body">
                  <form onSubmit={handleLogin}>
                    <div className="mb-3">
                      <input
                        type="text"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        className="form-control"
                        placeholder="Usuário"
                        id="nome"
                        aria-label="Usuário"
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Senha"
                        aria-label="Senha"
                        id="senha"
                        value={senha}
                        onChange={e => setSenha(e.target.value)}
                      />
                    </div>
                    <div className="text-center">
                      <button type="submit" className="btn bg-gradient-dark w-100 my-4 mb-2">Entrar</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
