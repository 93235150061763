import moment from 'moment';
import axios from 'axios';

export const bin2base64 = function (rawData) {
    return rawData.toString('base64');
}

export const utf8Encode = function (unicodeString) {
    if (typeof unicodeString != 'string')
        throw new TypeError('parameter ‘unicodeString’ is not a string');

    const utf8String = unicodeString
        .replace(/[\u0080-\u07ff]/g, (c) => {
            let cc = c.charCodeAt(0);
            // eslint-disable-next-line
            return String.fromCharCode(0xc0 | cc >> 6, 0x80 | cc & 0x3f);
        })
        .replace(/[\u0800-\uffff]/g, (c) => {
            let cc = c.charCodeAt(0);
            // eslint-disable-next-line
            return String.fromCharCode(0xe0 | cc >> 12, 0x80 | cc >> 6 & 0x3F, 0x80 | cc & 0x3f);
        });
    return utf8String;
}

export const utf8Decode = function (utf8String) {
    if (typeof utf8String != 'string')
        throw new TypeError('parameter ‘utf8String’ is not a string');

    const unicodeString = utf8String
        .replace(/[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g, (c) => {
            // eslint-disable-next-line
            return String.fromCharCode(((c.charCodeAt(0) & 0x0f) << 12) | ((c.charCodeAt(1) & 0x3f) << 6) | (c.charCodeAt(2) & 0x3f));
        })
        .replace(/[\u00c0-\u00df][\u0080-\u00bf]/g, (c) => {
            // eslint-disable-next-line
            return String.fromCharCode((c.charCodeAt(0) & 0x1f) << 6 | c.charCodeAt(1) & 0x3f);
        });
    return unicodeString;
}

export const hex2bin = function (hex) {
    var bytes = [], str;

    for (var i = 0; i < hex.length - 1; i += 2)
        bytes.push(parseInt(hex.substr(i, 2), 16));

    return String.fromCharCode.apply(String, bytes);
}

export const bin2hex = (s) => {
    //  discuss at: https://locutus.io/php/bin2hex/
    // original by: Kevin van Zonneveld (https://kvz.io)
    // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    // bugfixed by: Linuxworld
    // improved by: ntoniazzi (https://locutus.io/php/bin2hex:361#comment_177616)
    //   example 1: bin2hex('Kev')
    //   returns 1: '4b6576'
    //   example 2: bin2hex(String.fromCharCode(0x00))
    //   returns 2: '00'
    let i
    let l
    let o = ''
    let n
    s += ''
    for (i = 0, l = s.length; i < l; i++) {
        n = s.charCodeAt(i)
            .toString(16)
        o += n.length < 2 ? '0' + n : n
    }
    return o
}

export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
};

export const soNumeros = (texto) => {
    return texto.replace(/[^0-9]/g, '');
}//soNumeros

export const buscarCep = async (cep) => {
    cep = soNumeros(cep);
    if (cep.length === 8) {
        var resposta = await axios({
            method: "get",
            url: '//viacep.com.br/ws/' + cep + '/json/'
        });
        return resposta.data;
    } else {
        return { erro: true };
    }
};

export const extract = ({ jsonLaravel, chaves = [] }) => {
    var resultado = jsonLaravel;
    chaves.forEach((chave) => {
        if (jsonLaravel[chave]) {
            var id = jsonLaravel[chave].id;
            var nome = jsonLaravel[chave].nome;
            delete resultado[chave];
            resultado[chave] = id;
            resultado["nome" + capitalize(chave)] = nome;
            //return resultado;
        } else {
            resultado["nome" + capitalize(chave)] = null;
            //return resultado;
        }
    });
    return resultado;
};

export const datatostring = (data) => {
    if (data) {
        return (data.getFullYear() + '-' + ((data.getMonth() + 1)) + '-' + (data.getDate()));
    } else {
        return null;
    }
};

export const semValor = (valor) => {
    return ((valor === undefined) ||
        (valor === '') ||
        (valor === '0') ||
        (valor === '0,0') ||
        (valor === '0,00'));
};

export const rand6 = () => {
    return Math.floor(100000 + Math.random() * 900000);
};

export const rand18 = () => {
    return Math.floor(100000000000000000 + Math.random() * 900000000000000000).toString();
};

export const pontoVirgula = (valor) => {
    if (valor !== null && valor !== undefined) {
        return (valor + "").replace(".", ",") || "";
    } else {
        return "";
    }
};

export const virgulaPonto = (valor) => {
    if (valor) {
        return parseFloat(valor.replace(",", ".") || 0);
    } else {
        return null;
    }
};

export const dataMysql = (data) => {
    if (data) {
        return moment(data, "DD/MM/YYYY").format('YYYY-MM-DD');
    } else {
        return null;
    }
};

export const dataHoraMysql = (data) => {
    if (data) {
        return moment(data, "DD/MM/YYYYY HH:mm").format('YYYY-MM-DD HH:mm');
    } else {
        return null;
    }
};

export const data = (data) => {
    if (data) {
        return moment(data).format('DD/MM/YYYY');
    } else {
        return null;
    }
};

export const dataHora = (data) => {
    if (data) {
        return moment(data).format('DD/MM/YYYY HH:mm');
    } else {
        return null;
    }
};

export const limitaTexto = (str, limite = 20) => {
    if (str.length <= limite) {
        return str;
    } else {
        return str.substring(0, limite) + '...';
    }
};

export const dataHoraJs = (dataString) => {
    if (dataString) {
        var t = dataString.split(/[- :]/);
        return new Date(t[0], t[1] - 1, t[2], t[3], t[4]);
    } else {
        return null;
    }
};

export const hora = (str) => {
    if (str) {
        const [data, hora] = str.split(' ');
        const [horas, minutos] = hora.split(':');
        const horaFormatada = horas.padStart(2, '0');
        const minutoFormatado = minutos.padStart(2, '0');
        return `${horaFormatada}:${minutoFormatado}`;
    } else {
        return '';
    }
}

export const moeda = (n) => {
    var valor = parseFloat(n);
    if (n) {
        return valor.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+,)/g, "$1.");
    } else {
        return "0,00";
    }
}
