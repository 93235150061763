import React, { useState, useEffect } from 'react';
import { FaBuilding, FaUser, FaPen, FaGripVertical, FaBell, FaUsers, FaThumbsUp, FaThumbsDown, FaExclamationCircle, FaClock, FaPlaneArrival, FaPlaneDeparture, FaPlaneSlash, FaPlane } from 'react-icons/fa';
import api from '../services/api';

export default function DadosEventoParticipante({ idEvento, atualizarGrupo }) {
    const token = localStorage.getItem('token');
    const [dados, setDados] = useState({});

    const buscar = () => {
        //busca dados do evento
        api({
            method: 'get',
            action: '/evento/participantesStatusPorGrupos/' + idEvento,
            token: token,
        })
            .then(response => {
                var dadosGrupos = response.data.data;
                setDados(dadosGrupos);
            }).catch(err => {
                //console.log(err)
            });
    };

    useEffect(() => {
        if (idEvento) {
            buscar();
        }
    },
        // eslint-disable-next-line
        [idEvento]);

    return <>
        <div className="row">
            <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div
                    style={{ cursor: 'pointer' }}
                    className="card"
                    onClick={() => {
                        atualizarGrupo(null);
                    }}
                >
                    <div className="card-body p-3">
                        <div className="row">
                            <div className="col-8">
                                <div className="numbers">
                                    <p className="text-sm mb-0 text-uppercase font-weight-bold">Convidados</p>
                                    <h5 className="font-weight-bolder" style={{ marginBottom: "-0.5rem" }}>
                                        {dados.convidados ?? "0"}
                                    </h5>
                                </div>
                            </div>
                            <div className="col-4 text-end">
                                <div className="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle">
                                    <FaUsers size={20} color='#FFF' style={{ marginTop: "15px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div
                    style={{ cursor: 'pointer' }}
                    className="card"
                    onClick={() => {
                        atualizarGrupo("pendentes");
                    }}
                >
                    <div className="card-body p-3">
                        <div className="row">
                            <div className="col-8">
                                <div className="numbers">
                                    <p className="text-sm mb-0 text-uppercase font-weight-bold">Pendentes</p>
                                    <h5 className="font-weight-bolder" style={{ marginBottom: "-0.5rem" }}>
                                        {dados.pendentes ?? "0"}
                                    </h5>
                                </div>
                            </div>
                            <div className="col-4 text-end">
                                <div className="icon icon-shape bg-gradient-warning shadow-warning text-center rounded-circle">
                                    <FaExclamationCircle size={20} color='#FFF' style={{ marginTop: "15px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div
                    style={{ cursor: 'pointer' }}
                    className="card"
                    onClick={() => {
                        atualizarGrupo("standby");
                    }}
                >
                    <div className="card-body p-3">
                        <div className="row">
                            <div className="col-8">
                                <div className="numbers">
                                    <p className="text-sm mb-0 text-uppercase font-weight-bold">Standby</p>
                                    <h5 className="font-weight-bolder" style={{ marginBottom: "-0.5rem" }}>
                                        {dados.standby ?? "0"}
                                    </h5>
                                </div>
                            </div>
                            <div className="col-4 text-end">
                                <div className="icon icon-shape bg-gradient-faded-dark shadow-danger text-center rounded-circle">
                                    <FaClock size={20} color='#FFF' style={{ marginTop: "15px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div
                    style={{ cursor: 'pointer' }}
                    className="card"
                    onClick={() => {
                        atualizarGrupo("declinaram");
                    }}
                >
                    <div className="card-body p-3">
                        <div className="row">
                            <div className="col-8">
                                <div className="numbers">
                                    <p className="text-sm mb-0 text-uppercase font-weight-bold">Declinaram</p>
                                    <h5 className="font-weight-bolder" style={{ marginBottom: "-0.5rem" }}>
                                        {dados.declinaram ?? "0"}
                                    </h5>
                                </div>
                            </div>
                            <div className="col-4 text-end">
                                <div className="icon icon-shape bg-gradient-danger shadow-danger text-center rounded-circle">
                                    <FaThumbsDown size={20} color='#FFF' style={{ marginTop: "15px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row py-2">
            <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div
                    style={{ cursor: 'pointer' }}
                    className="card"
                    onClick={() => {

                        atualizarGrupo("semAereo");
                    }}
                >
                    <div className="card-body p-3">
                        <div className="row">
                            <div className="col-8">
                                <div className="numbers">
                                    <p className="text-sm mb-0 text-uppercase font-weight-bold">Confirmados  <br />Sem Aéreo</p>
                                    <h5 className="font-weight-bolder" style={{ marginBottom: "-0.5rem" }}>
                                        {dados.semAereo ?? "0"}
                                    </h5>
                                </div>
                            </div>
                            <div className="col-4 text-end">
                                <div className="icon icon-shape bg-gradient-success shadow-success text-center rounded-circle">
                                    <FaThumbsUp size={20} color='#FFF' style={{ marginTop: "15px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div
                    style={{ cursor: 'pointer' }}
                    className="card"
                    onClick={() => {
                        atualizarGrupo("aereoEmissao");
                    }}
                >
                    <div className="card-body p-3">
                        <div className="row">
                            <div className="col-8">
                                <div className="numbers">
                                    <p className="text-sm mb-0 text-uppercase font-weight-bold">Confirmados Aéreos em emissão</p>
                                    <h5 className="font-weight-bolder" style={{ marginBottom: "-0.5rem" }}>
                                        {dados.aereoEmissao ?? "0"}
                                    </h5>
                                </div>
                            </div>
                            <div className="col-4 text-end">
                                <div className="icon icon-shape bg-gradient-info shadow-danger text-center rounded-circle">
                                    <FaPlane size={20} color='#FFF' style={{ marginTop: "15px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div
                    style={{ cursor: 'pointer' }}
                    className="card"
                    onClick={() => {
                        atualizarGrupo("aereoEmitido");
                    }}
                >
                    <div className="card-body p-3">
                        <div className="row">
                            <div className="col-8">
                                <div className="numbers">
                                    <p className="text-sm mb-0 text-uppercase font-weight-bold">Confirmados Aéreos Emitidos</p>
                                    <h5 className="font-weight-bolder" style={{ marginBottom: "-0.5rem" }}>
                                        {dados.aereoEmitido ?? "0"}
                                    </h5>
                                </div>
                            </div>
                            <div className="col-4 text-end">
                                <div className="icon icon-shape bg-gradient-warning shadow-danger text-center rounded-circle">
                                    <FaPlaneDeparture size={20} color='#FFF' style={{ marginTop: "15px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}