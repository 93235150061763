import React, { useState, useEffect, useMemo } from 'react';
import ErpClienteTemplate from '../../../components/ErpClienteTemplate';
import NavBarCliente from '../../../components/NavBarCliente';
import api from '../../../services/api';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { FaBackward, FaSearch } from 'react-icons/fa';

export default function PainelEventoParticipante({ match }) {
    let { search, state } = useLocation();
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const [dados, setDados] = useState({});
    const [historico, setHistorico] = useState([]);

    const buscar = () => {
        //busca participante
        api({
            method: 'get',
            action: '/participante/' + id,
            token: token,
        })
            .then(response => {
                setDados(response.data.data);
                //atualiza lista de historico do participante
                buscarHistorico(id);
            }).catch(err => {
                //console.log(err)
            });
    };

    const buscarHistorico = (idParticipante) => {
        api({
            method: "get",
            action: '/participanteHistoricoSituacao',
            token: token,
            params: { participante: idParticipante }
        }).then(response => {
            var dadosRecebidosHistorico = response.data.data;
            setHistorico(dadosRecebidosHistorico);
        }).catch(err => {
            //console.log(err)
        });
    };


    useEffect(() => {
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpClienteTemplate>
            <main className="main-content position-relative border-radius-lg ">
                <NavBarCliente
                    titulo={
                        <Link to={'/eventoParticipante/' + (dados.evento?.id ?? null)}>
                            <button type="button" className="btn btn-primary me-1 mb-1" style={{ minWidth: "105px" }}>
                                <FaBackward size={20} /> Voltar
                            </button>
                        </Link>
                    } />
                <div className="container-fluid">
                    <h5 className="font-weight-bolder text-white mb-0">{(dados.evento?.nomersvp ?? dados.evento?.nome ?? "")}</h5>
                    <h6 className="font-weight-bolder text-white mb-0">{dados.nome ?? ""}</h6>
                    <div className="row mt-2">
                        <div className="col-lg-12 mb-lg-0 mb-4">
                            <div className="card">
                                <div className="card-header pb-0">
                                </div>
                                <div className="card-body px-0 pt-0 pb-2">
                                    <div className="table-responsive">
                                        <DataTable
                                            noContextMenu="true"
                                            selectableRows={false}
                                            noDataComponent="Nenhum registro"
                                            paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                                            pagination="true"
                                            className="table mb-0"
                                            columns={[
                                                {
                                                    name: 'Data',
                                                    selector: (row) => {
                                                        return row.created_at ?? "";
                                                    },
                                                    width: "120px",
                                                    cell: (row) => {
                                                        if (row.created_at) {
                                                            return moment(row.created_at).format("DD/MM/YYYY HH:mm");
                                                        } else {
                                                            return "";
                                                        }
                                                    },
                                                },
                                                {
                                                    name: 'Status',
                                                    selector: (row) => {
                                                        return row.situacao?.nome ?? "";
                                                    },
                                                    cell: (row) => {
                                                        return row.situacao?.nome ?? "";
                                                    },
                                                    width: "200px",
                                                },
                                                {
                                                    name: 'Observação',
                                                    selector: (row) => {
                                                        return row.historico ?? "";
                                                    },
                                                    cell: (row) => {
                                                        return row.historico ?? "";
                                                    },
                                                },
                                            ]}
                                            data={historico}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </ErpClienteTemplate >
    );
}