import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/Login';
import PainelEvento from './pages/ClientesEvento/PainelEvento';
import PainelEventoParticipante from './pages/ClientesEvento/Participante/PainelEventoParticipante';
import PainelEventoParticipanteDetalhes from './pages/ClientesEvento/Participante/PainelEventoParticipanteDetalhes';
import EventoRelatorioParticipante from './pages/ClientesEvento/EventoRelatorioParticipante';
import CadastroEventoPergunta from './pages/Evento/Pergunta/CadastroEventoPergunta';
import PainelEventoPerguntaModeracao from './pages/Evento/Pergunta/PainelEventoPerguntaModeracao';
import PainelEventoPerguntaAprovada from './pages/Evento/Pergunta/PainelEventoPerguntaAprovada';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={localStorage.getItem("token") ? PainelEvento : Login} />
        <Route path="/login" exact component={Login} />
        <Route path="/cadastroEventoPergunta/:id?" component={CadastroEventoPergunta} />
        <Route path="/eventoPerguntaModeracao/:id?" component={PainelEventoPerguntaModeracao} />
        <Route path="/eventoPerguntaAprovada/:id?" component={PainelEventoPerguntaAprovada} />
        <PrivateRoute path="/evento" component={PainelEvento} />
        <PrivateRoute path="/eventoParticipante/:id?" component={PainelEventoParticipante} />
        <PrivateRoute path="/eventoParticipanteDetalhes/:id?" component={PainelEventoParticipanteDetalhes} />
        <PrivateRoute path="/eventoRelatorioParticipante/:id?" component={EventoRelatorioParticipante} />
      </Switch>
    </BrowserRouter>
  );
}