import React from 'react';
import IconButton from './IconButton';

export default function FormButton({
    method = "get",
    target = "_blank",
    action = "",
    icon,
    children,
    color,
    small = false,
    type = "submit",
    data = null,
    title = "",
    style = null
}) {
    return (
        <form style={{ ...style, ...{ lineHeight: 0 } }} method={method} target={target} action={action} onSubmit={(e) => { /*e.preventDefault();*/ }}>
            {data ? <input type="hidden" name="data" value={JSON.stringify(data)} /> : null}
            <IconButton
                title={title}
                small={small}
                type={type}
                icon={icon}
                color={color}>
                {children}
            </IconButton>
        </form>
    );
}