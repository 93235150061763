import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import ErpClienteTemplate from '../../components/ErpClienteTemplate';
import NavBarCliente from '../../components/NavBarCliente';
import ErpTemplate from '../../components/ErpTemplate';
import { FaList, FaUpload, FaCalendarAlt, FaPen, FaPlus, FaSearch, FaThumbsUp, FaUsers, FaTable } from 'react-icons/fa';
import { BsCardChecklist } from 'react-icons/bs';
import { GrMail } from "react-icons/gr";
import { ImCancelCircle } from 'react-icons/im';
import { GiOpenChest } from 'react-icons/gi';
import NavItem from '../../components/NavItem';
import IconButton from '../../components/IconButton';
import Alert from '../../components/Alert';
import api from '../../services/api';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Data from '../../components/Data';
import { dataMysql } from '../../util/util';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import ReactSelect from '../../components/ReactSelect';
import { toast } from 'react-toastify';
import FormButton from '../../components/FormButton';

export default function PainelEvento() {
  const token = localStorage.getItem('token');
  const empresa = localStorage.getItem("empresa");
  const perfil = localStorage.getItem("perfil");
  const [filtro, setFiltro] = useState({});
  const [dados, setDados] = useState({});
  const [eventos, setEventos] = useState([]);
  const params = new URLSearchParams(window.location.search);
  const idEvento = params.get('idEvento');

  const buscar = () => {
    setFiltro({ ...filtro, ...{ buscando: true } });
    api({
      method: 'get',
      action: '/evento',
      token: token,
      params: {
        ...filtro,
        ...{ perfil: perfil },
        ...{ rsvp: "sim" },
        ...{ id: idEvento },
        ...{
          orderBy: {
            situacao: "asc",
            data: "desc",
          }
        },
      }
    })
      .then(response => {
        setEventos(response.data.data);
        setFiltro({ ...filtro, ...{ buscando: false } });
      }).catch(err => {
        //console.log(err)
      })
  };

  useEffect(() => { //onload
    document.querySelector("#nome")?.focus(); //autofocus no nome
    buscar();
  },
    // eslint-disable-next-line
    []);

  return (
    <ErpClienteTemplate>
      <main className="main-content position-relative border-radius-lg ">
        <NavBarCliente titulo='Eventos' />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mb-lg-0 mb-4">
              <div className="card">
                <div className="card-header pb-0">
                  <form className="form" onSubmit={(e) => { buscar(); e.preventDefault(); }}>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-floating form-group">
                          <input type="text" className="form-control form-control-lg" id="nome" placeholder="Buscar pelo nome do evento"
                            onChange={(e) => {
                              filtro.nome = e.target.value;
                              setFiltro({ ...filtro });
                            }}
                          />
                          <label htmlFor="floatingInput">Nome do Evento</label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary me-1 mb-1">
                          <FaSearch size={20} /> Buscar
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-body px-0 pt-0 pb-2">
                  <div className="table-responsive">
                    <DataTable
                      progressPending={filtro.buscando ?? false}
                      progressComponent={<div>Buscando...</div>}
                      noContextMenu="true"
                      selectableRows={false}
                      loadOptions
                      noDataComponent="Nenhum registro"
                      paginationComponentOptions={{ rowsPerPageText: 'Linhas', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' }}
                      pagination="true"
                      className="table mb-0"
                      columns={[
                        {
                          name: '',
                          width: "200px",
                          cell: (row) => {
                            return <IconButton
                              style={{ marginBottom: "0px" }}
                              small={true}
                              to={"/eventoParticipante/" + row.id}
                              icon={<FaUsers size={20} />}
                              color="primary">Participantes</IconButton>;
                          }
                        },
                        {
                          name: 'Nome do evento',
                          selector: row => row.nomersvp ?? row.nome,
                          sortable: true,
                          cell: (row) => {
                            return row.nomersvp ?? row.nome;
                          },
                        },
                        {
                          name: 'Situação',
                          selector: row => row.situacao ?? "",
                          sortable: true,
                          width: "100px",
                        },
                        {
                          name: 'Data',
                          selector: row => row.data ?? "",
                          sortable: true,
                          width: "120px",
                          cell: (row) => {
                            var dataCompetencia = row.dataCompetencia ? " " + moment(row.dataCompetencia).format("MM/YYYY") : "";
                            var dataInicio = row.data ? " " + moment(row.data).format("DD/MM/YYYY") : "";
                            dataInicio = row.replanejadoInicio ? " " + moment(row.replanejadoInicio).format("DD/MM/YYYY") : dataInicio;
                            var dataFim = row.dataFim ? " " + moment(row.dataFim).format("DD/MM/YYYY") : "";
                            dataFim = row.replanejadoFim ? " " + moment(row.replanejadoFim).format("DD/MM/YYYY") : dataFim;
                            var dataCancelamento = row.dataCancelamento ? " Cancelado: " + moment(row.dataCancelamento).format("DD/MM/YYYY") : "";
                            var dataFechamento = row.dataFechamento ? " Fechado: " + moment(row.dataFechamento).format("DD/MM/YYYY") : "";
                            if (dataInicio === dataFim) {
                              return dataCompetencia + dataInicio + dataFechamento + dataCancelamento;
                            } else {
                              return dataCompetencia + dataInicio + dataFim + dataFechamento + dataCancelamento;
                            }
                          }
                        }
                      ]}
                      data={eventos}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </ErpClienteTemplate>
  );
}