import React from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../images/logo.svg';

export default function SideBarCliente() {
    const location = useLocation();
    const perfil = localStorage.getItem("perfil");
    const logomarcaEvento = localStorage.getItem("logomarcaEvento");

    return (
        <aside
            className="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4"
            id="sidenav-main">
            <div className="sidenav-header">
                <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
                    aria-hidden="true" id="iconSidenav"></i>
                <a className="navbar-brand m-0" href="/evento">
                    <img src={logomarcaEvento ? logomarcaEvento : logo} className="navbar-brand-img h-100" alt="main_logo" />
                </a>
            </div>
            <hr className="horizontal dark mt-0" />
            <div className="w-auto" id="sidenav-collapse-main">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link active" href="/evento">
                            <div
                                className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                <i className="ni ni-calendar-grid-58 text-warning text-sm opacity-10"></i>
                            </div>
                            <span className="nav-link-text ms-1">Eventos</span>
                        </a>
                    </li>
                </ul>
            </div>
        </aside>
    );
}