import React from 'react';
import Processando from './Processando';
import SideBarCliente from './SideBarCliente';
import { useAppContext } from '../AppContext';

import "../argon-dashboard/assets/css/nucleo-icons.css";
import "../argon-dashboard/assets/css/nucleo-svg.css";
import "../argon-dashboard/assets/css/argon-dashboard.css";
import "../css/custom.css";


export default function ErpClienteTemplate({ children, sidebar = true, processando = false, imagemfundo = null }) {
    return (
        <div className={"g-sidenav-show bg-gray-100 vsc-initialized"}>
            <div
                className="min-height-300 bg-primary position-absolute w-100"
                style={{
                    backgroundImage: `url(${imagemfundo})`,
                    backgroundSize: 'contain',
                }}>
            </div>
            {sidebar ? <SideBarCliente /> : null}
            {children}
            <div style={{ height: "100px" }}></div>
            {processando && <Processando />}
        </div>
    );
}