import React from 'react';
import Routes from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppProvider } from './AppContext';

function App() {
  return (
    <div id="app">
      <AppProvider>
        <Routes />
        <ToastContainer />
      </AppProvider>
    </div>
  );
}

export default App;